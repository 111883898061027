var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('editProvider'))+" ")])])],1),_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('validation-observer',{ref:"supplierValidation"},[_c('b-row',[_c('b-col',[_c('b-card-body',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('companyName')}},[_c('validation-provider',{attrs:{"name":"Nombre de la empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.supplier.name),callback:function ($$v) {_vm.$set(_vm.supplier, "name", $$v)},expression:"supplier.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre del representante"}},[_c('validation-provider',{attrs:{"name":"Nombre del representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg","autocomplete":"off","type":"text"},model:{value:(_vm.supplier.contact_name),callback:function ($$v) {_vm.$set(_vm.supplier, "contact_name", $$v)},expression:"supplier.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email del representante"}},[_c('validation-provider',{attrs:{"name":"Email del representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"font-weight-bolder",attrs:{"size":"lg"},model:{value:(_vm.supplier.email),callback:function ($$v) {_vm.$set(_vm.supplier, "email", $$v)},expression:"supplier.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('companyAddress')}},[_c('gmap-autocomplete',{ref:"googleAddress",staticClass:"form-control",on:{"place_changed":function($event){return _vm.updateCenter($event)}}})],1)],1),(_vm.currentUser.role_name === 'admin')?_c('b-col',{attrs:{"sm":"12","lg":"6"}},[(_vm.supplier.approved_status === 'approved')?_c('b-form-group',{attrs:{"label":_vm.$t('toggleStatus')}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.confirmChangeStatus",modifiers:{"confirmChangeStatus":true}}],attrs:{"size":"sm","variant":_vm.supplier.active_status
                          ? 'outline-success'
                          : 'outline-danger'}},[_vm._v(_vm._s(_vm.$t('status'))+": "+_vm._s(_vm.supplier.active_status ? _vm.$t('active') : _vm.$t('unactive')))])],1):_vm._e()],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('files-table',{attrs:{"files-requested":_vm.filesRequested}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between"},[(
                      (_vm.allFilesSubmitted &&
                        _vm.supplier.approved_status === 'pending') ||
                        _vm.supplier.approved_status === 'denied'
                    )?_c('b-button',{staticClass:"px-5",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.askForApproval}},[_vm._v(" "+_vm._s(_vm.$t('askForApproval'))+" ")]):_vm._e(),(_vm.currentUser.role_name === 'admin')?_c('div',{staticClass:"d-flex"},[(_vm.supplier.approved_status === 'awaiting_review')?_c('b-button',{staticClass:"px-2 mr-2",attrs:{"variant":"outline-success","size":"sm"},on:{"click":_vm.approve}},[_vm._v(" "+_vm._s(_vm.$t('approve'))+" ")]):_vm._e(),(_vm.supplier.approved_status === 'awaiting_review')?_c('b-button',{staticClass:"px-2",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":_vm.reject}},[_vm._v(" "+_vm._s(_vm.$t('reject'))+" ")]):_vm._e()],1):_vm._e(),_c('b-button',{staticClass:"px-5",attrs:{"variant":"primary","size":"sm","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1),_c('b-col',[_c('div',{attrs:{"id":"root"}},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"750px"},attrs:{"center":_vm.center,"zoom":12}},[_c('GmapMarker',{attrs:{"position":_vm.marker}})],1)],1)])],1)],1)],1),_c('confirmation-modal',{attrs:{"id":'confirmChangeStatus',"variant":_vm.supplier.active_status ? 'danger' : 'success'},on:{"confirm":function($event){return _vm.changeStatus()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.supplier.active_status ? _vm.$t('deleteSupplierConfirmation', { supplier: _vm.supplier.name, }) : _vm.$t('reactivateSupplierConfirmation', { supplier: _vm.supplier.name, }))+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }