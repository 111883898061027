<template>
  <div>
    <b-table
      :items="filesRequested"
      :fields="fields"
      responsive
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(loaded)="data">
        <b-badge :variant="loadStatusColor(data.value)">
          {{ loadStatusLabel(data.value) }}
        </b-badge>
      </template>
      <template #cell(tools)="data">
        <b-btn
          size="sm"
          variant="outline-secondary"
          @click="openFileInput(data.index)"
        >
          <feather-icon
            size="12"
            icon="FileTextIcon"
          />
        </b-btn>

        <b-btn
          v-if="data.item.loaded === 'awaitingUpload'"
          size="sm"
          variant="outline-secondary"
          @click="upload(data.item)"
        >
          <feather-icon
            size="12"
            icon="UploadIcon"
          />
        </b-btn>

        <b-btn
          v-if="data.item.loaded === 'loaded'"
          size="sm"
          variant="outline-secondary"
          :href="data.item.document_attachment"
          target="_blank"
        >
          <feather-icon
            size="12"
            icon="DownloadIcon"
          />
        </b-btn>

        <b-form-file
          :ref="`file-${data.index}`"
          v-model="data.item.file_64"
          style="display: none;"
          plain
          @input="selectFile({ file: $event, item: data.item })"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},

  props: {
    filesRequested: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      fields: [
        {
          key: 'file_name',
          label: this.$t('name'),
          tdClass: 'text-left',
          thClass: 'text-left',
          thStyle: 'width:20%',
        },
        {
          key: 'loaded',
          label: this.$t('status'),
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: 'width:20%',
        },
        {
          key: 'tools',
          label: this.$t('tools'),
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: 'width:60%',
        },
      ],
    }
  },

  computed: {
    ...mapState(['apiUrl']),
  },

  methods: {
    ...mapMutations('suppliers', {
      removeFile: 'REMOVE_FILE',
    }),
    ...mapActions('suppliers', ['uploadSupplierDocument']),

    loadStatusColor(status) {
      switch (status) {
        case 'notLoaded':
          return 'danger'
        case 'awaitingUpload':
          return 'warning'
        case 'loaded':
          return 'success'
        default:
          return 'info'
      }
    },

    loadStatusLabel(status) {
      switch (status) {
        case 'notLoaded':
          return this.$t('notUploaded')
        case 'awaitingUpload':
          return this.$t('awaitingUpload')
        case 'loaded':
          return this.$t('loaded')
        default:
          return 'info'
      }
    },

    openFileInput(index) {
      this.$refs[`file-${index}`].$el.click()
    },
    upload(document) {
      this.$getBase64(document.file_64).then(data => {
        this.uploadSupplierDocument({
          supplierId: this.$route.params.supplierId,
          documentId: document.id,
          attachment: { data },
        }).then(response => {
          document.document_attachment = response.document_attachment
          document.loaded = 'loaded'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('documentUploaded'),
              icon: 'UploadIcon',
              variant: 'success',
            },
          })
        })
      })
    },
    /* eslint-disable-next-line */
    selectFile({ file, item }) {
      item.loaded = 'awaitingUpload'
    },
  },
}
</script>

<style lang="scss" scoped></style>
