<template>
  <div>
    <b-row>
      <b-col>
        <h1 class="font-weight-bolder">
          {{ $t('editProvider') }}
        </h1>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <validation-observer ref="supplierValidation">
        <b-row>
          <b-col>
            <b-card-body>
              <b-form @submit.prevent="validateForm()">
                <b-row>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group :label="$t('companyName')">
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre de la empresa"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.name"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group label="Nombre del representante">
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre del representante"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.contact_name"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group label="Email del representante">
                      <validation-provider
                        #default="{ errors }"
                        name="Email del representante"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.email"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group :label="$t('phone')">
                      <validation-provider
                        #default="{ errors }"
                        name="Email del representante"
                        rules="required"
                      >
                        <b-form-input
                          v-model="supplier.phone"
                          size="lg"
                          class="font-weight-bolder"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col sm="12">
                    <b-form-group :label="$t('companyAddress')">
                      <gmap-autocomplete
                        ref="googleAddress"
                        class="form-control"
                        @place_changed="updateCenter($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="currentUser.role_name === 'admin'"
                    sm="12"
                    lg="6"
                  >
                    <b-form-group
                      v-if="supplier.approved_status === 'approved'"
                      :label="$t('toggleStatus')"
                    >
                      <b-button
                        v-b-modal.confirmChangeStatus
                        size="sm"
                        :variant="
                          supplier.active_status
                            ? 'outline-success'
                            : 'outline-danger'
                        "
                      >{{ $t('status') }}:
                        {{
                          supplier.active_status ? $t('active') : $t('unactive')
                        }}</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <files-table :files-requested="filesRequested" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex justify-content-between">
                    <b-button
                      v-if="
                        (allFilesSubmitted &&
                          supplier.approved_status === 'pending') ||
                          supplier.approved_status === 'denied'
                      "
                      variant="primary"
                      size="sm"
                      class="px-5"
                      @click="askForApproval"
                    >
                      {{ $t('askForApproval') }}
                    </b-button>
                    <div
                      v-if="currentUser.role_name === 'admin'"
                      class="d-flex"
                    >
                      <b-button
                        v-if="supplier.approved_status === 'awaiting_review'"
                        variant="outline-success"
                        size="sm"
                        class="px-2 mr-2"
                        @click="approve"
                      >
                        {{ $t('approve') }}
                      </b-button>
                      <b-button
                        v-if="supplier.approved_status === 'awaiting_review'"
                        variant="outline-danger"
                        size="sm"
                        class="px-2"
                        @click="reject"
                      >
                        {{ $t('reject') }}
                      </b-button>
                    </div>
                    <b-button
                      variant="primary"
                      size="sm"
                      class="px-5"
                      type="submit"
                    >
                      {{ $t('save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-col>
          <b-col>
            <div id="root">
              <gmap-map
                ref="map"
                :center="center"
                :zoom="12"
                style="width: 100%; height: 750px"
              >
                <!-- TODO add pin -->
                <GmapMarker :position="marker" />
              </gmap-map>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <confirmation-modal
      :id="'confirmChangeStatus'"
      :variant="supplier.active_status ? 'danger' : 'success'"
      @confirm="changeStatus()"
    >
      <template v-slot:content>
        {{
          supplier.active_status
            ? $t('deleteSupplierConfirmation', {
              supplier: supplier.name,
            })
            : $t('reactivateSupplierConfirmation', {
              supplier: supplier.name,
            })
        }}
      </template>
    </confirmation-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilesTable from '@/components/tables/UploadFilesTable.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    FilesTable,
    ValidationProvider,
    ValidationObserver,
    ConfirmationModal,
  },

  data() {
    return {
      supplier: {
        name: '',
        contact_name: '',
        address_attributes: {
          state: '',
          country: '',
          city: '',
          street: '',
          ext_number: '',
          postal_code: '',
          lat: '',
          lng: '',
          suburb: '',
        },
      },
      companyName: '',
      companyAddress: '',
      required,
      email,

      center: { lat: 25.6866142, lng: -100.3161126 },
      marker: {},
      filesRequested: [],
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers', 'newProviderFiles']),
    ...mapGetters('auth', ['currentUser']),
    allFilesSubmitted() {
      return (
        this.filesRequested.every(file => file.document_attachment?.includes('/rails/')) && this.filesRequested.length
      )
    },
  },

  created() {
    this.fetchSupplier(this.$route.params.supplierId).then(response => {
      this.supplier = response
      this.center = {
        lat: parseFloat(response.address.lat),
        lng: parseFloat(response.address.lng),
      }
      this.marker = {
        lat: parseFloat(response.address.lat),
        lng: parseFloat(response.address.lng),
      }
      const address = JSON.parse(JSON.stringify(response.address))
      this.$refs.googleAddress.$el.value = address.formatted_address
    })
    this.fetchSupplierDocuments(this.$route.params.supplierId).then(
      response => {
        this.filesRequested = response.data.map(file => {
          let loadStatus = 'notLoaded'
          if (file.document_attachment !== null) {
            loadStatus = 'loaded'
          }
          return {
            ...file,
            file_name: file.document_name,
            loaded: loadStatus,
          }
        })
      },
    )
  },

  methods: {
    ...mapActions('suppliers', [
      'fetchSupplier',
      'editSupplier',
      'fetchSupplierDocuments',
      'requestApproval',
      'approveSupplier',
      'rejectSupplier',
      'changeSupplierStatus',
    ]),
    updateCenter(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.companyAddress = place
      const address = {
        name: '',
        state: '',
        country: '',
        city: '',
        street: '',
        ext_number: '',
        int_number: '',
        postal_code: '',
        lat: '',
        lng: '',
        url: '',
        suburb: '',
        formatted_address: '',
      }
      place.address_components.forEach(x => {
        if (x.types.includes('administrative_area_level_1')) {
          address.state = x.long_name
        }
        if (x.types.includes('country')) {
          address.country = x.long_name
        }
        if (x.types.includes('city') || x.types.includes('locality')) {
          address.city = x.long_name
        }
        if (x.types.includes('street') || x.types.includes('route')) {
          address.street = x.long_name
        }
        if (
          x.types.includes('ext_number')
          || x.types.includes('street_number')
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes('postal_code')) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes('suburb')
          || x.types.includes('sublocality_level_1')
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      this.supplier.address_attributes = address
    },
    validateForm() {
      this.$refs.supplierValidation.validate().then(success => {
        if (success) {
          this.editSupplier({
            ...this.supplier,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('supplierEdited'),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              if (this.currentUser.role_name === 'admin') {
                this.$router.push({ name: 'suppliers' })
              }
            })
        }
      })
    },

    askForApproval() {
      this.requestApproval(this.$route.params.supplierId).then(response => {
        this.supplier = response
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('approvalRequested'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
    approve() {
      this.approveSupplier(this.$route.params.supplierId).then(response => {
        this.supplier = response
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('supplierApproved'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
    reject() {
      this.rejectSupplier(this.$route.params.supplierId).then(response => {
        this.supplier = response
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('supplierRejected'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },

    changeStatus() {
      this.changeSupplierStatus(this.$route.params.supplierId).then(
        response => {
          this.supplier = response
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('statusUpdated'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        },
      )
    },
  },
}
</script>

<style></style>
